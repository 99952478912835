import React from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const SocialIcons = () => {

  return (
    <div className="social">
      <a className="facebook" title="facebook" href="https://www.facebook.com/humanmarketingoc/" target="_blank" rel="noopener noreferrer"> 
        <FontAwesomeIcon icon={['fab', 'facebook-f']} />
      </a>
      <a className="twitter" title="twitter" href="https://twitter.com/MarketingHumans" target="_blank" rel="noopener noreferrer">                      
        <FontAwesomeIcon icon={['fab', 'twitter']} />
      </a>
      <a className="linkedin" title="linkedin" href="https://www.linkedin.com/company/human.marketing/" target="_blank" rel="noopener noreferrer">                      
        <FontAwesomeIcon icon={['fab', 'linkedin-in']} />
      </a>
      <a className="instagram" title="instagram" href="https://www.instagram.com/human.marketing/" target="_blank" rel="noopener noreferrer">                      
        <FontAwesomeIcon icon={['fab', 'instagram']} />
      </a>
      <a className="youtube" title="youtube" href="https://www.youtube.com/channel/UCR8xqcLs0S4M-KoVFnPriQA" target="_blank" rel="noopener noreferrer">                    
        <FontAwesomeIcon icon={['fab', 'youtube']} />
      </a>
    </div>
  );
};

export default SocialIcons;
