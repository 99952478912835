import React from 'react'
import { Link } from 'gatsby'
import logo from '../img/logo.png'
import SocialIcons from '../components/SocialIcons'
import BlueprintFooterCta from '../components/BlueprintFooterCta'
import PersonalizeFooterCta from '../components/PersonalizeFooterCta'

const Footer = ({ software, waves = true }) => {
  return (
    <footer className={['footer has-text-white-ter', !waves && 'no-waves'].filter(Boolean).join(' ')} id="footer-section">
      {waves && (
        <div className="footer__waves--wrapper">
          <div className="footer__waves">
            <div className="waves-wrapper">
              <svg className="waveOne" width="1440px" viewBox="0 0 1440 158" version="1.1">
                <defs>
                  <linearGradient x1="50%" y1="0%" x2="50%" y2="86.3509514%" id="linearGradient-1a">
                      <stop stopColor="#11A9BC" offset="0%"></stop>
                      <stop stopColor="#0096A9" offset="100%"></stop>
                  </linearGradient>
                </defs>
                <g id="page" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                  <g id="it-caseStudy-desktop" transform="translate(0.000000, -3295.000000)" stroke="#CCEAEE" strokeWidth="10">
                    <path d="M0,3340 C89.5,3340 89.7461328,3300 180,3300 C270.253867,3300 271.007692,3340 361,3340 C450.992308,3340 450.246175,3300 540,3300 C629.753825,3300 630,3340 720,3340 C810,3340 810,3300 900,3300 C990,3300 990,3340 1080,3340 C1170,3340 1170,3300 1260,3300 C1350,3300 1350,3340 1440,3340" id="Path-3-Copy"></path>
                  </g>
                </g>
              </svg>
            </div>
          </div>
        </div>
      )}
      <div className="content has-text-white-ter footer__content">
        <div className="container has-text-white-ter">
          <div className="columns is-12">
            <div className="column">
              <section className="footer--cta" id="footer-cta">
                {software === 'blueprint-seo'
                  ? <BlueprintFooterCta/>
                  : <PersonalizeFooterCta/>
                }
              </section>
            </div>
          </div>
        </div>
      </div>
      <div className="footer__waves--bottom">
        <svg className="waveTwo" width="1440px" viewBox="0 0 1440 158" version="1.1">
          <defs>
            <linearGradient x1="50%" y1="0%" x2="50%" y2="86.3509514%" id="linearGradient-1c">
              <stop stopColor="#11A9BC" offset="0%"></stop>
              <stop stopColor="#11A9BC" offset="35.7553843%"></stop>
              <stop stopColor="#11A9BC" offset="100%"></stop>
              <stop stopColor="#11A9BC" offset="100%"></stop>
            </linearGradient>
          </defs>
          <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g id="it-caseStudy-desktop" transform="translate(0.000000, -3528.000000)">
              <g id="Group-4" transform="translate(0.000000, 3616.000000)">
                <g id="Group-30">
                  <path d="M0.549105703,43.8985507 C89.9818841,43.8985507 90.227832,1 180.413911,1 C270.599991,1 271.35325,43.8985507 361.277966,43.8985507 C451.202682,43.8985507 450.45711,1 540.143523,1 C629.829936,1 630.075926,43.8985507 720.008329,43.8985507 C809.940731,43.8985507 809.940731,1 899.873134,1 C989.805537,1 989.805537,43.8985507 1079.73794,43.8985507 C1169.67034,43.8985507 1169.67034,1 1259.60275,1 C1349.53515,1 1349.53515,43.8985507 1439.46755,43.8985507 C1439.60046,43.9661836 1439.60046,54.3333333 1439.46755,75 L0.549105703,75 C0.582739157,54.3333333 0.582739157,43.9661836 0.549105703,43.8985507 Z" id="Path-3" fill="#11a9bc"></path>
                  <path d="M0.599555885,43 C90.0415896,43 90.2875629,0 180.482976,0 C270.678388,0 271.431725,43 361.365748,43 C451.29977,43 450.554121,0 540.249815,0 C629.945509,0 630.191525,43 720.133235,43 C810.074944,43 810.074944,0 900.016654,0 C989.958364,0 989.958364,43 1079.90007,43 C1169.84178,43 1169.84178,0 1259.78349,0 C1349.7252,0 1349.7252,43 1439.66691,43" id="Path-3" stroke="#CCEAEE" strokeWidth="10"></path>
                </g>
              </g>
            </g>
          </g>
        </svg>
      </div>
      <div className="content has-text-white-ter footer__content" style={{ backgroundColor:`#11A9BC`, paddingBottom: `3rem` }}>
        <div className="container has-text-white-ter">
          <div className="columns footer__menu--container">
            <div className="column is-8">
              <div className="columns footer__menu">
                <div className="column">
                  <section className="menu">
                    <ul className="menu-list">
                      <li>
                        <h3>Personalize</h3>
                        <div className="submenu">
                          <Link className="navbar-item" to="/personalize/">Overview</Link>
                          <Link className="navbar-item" to="/personalize/work/new-horizons-case-study/">Use Cases</Link>
                          <Link className="navbar-item" to="http://help.humanautomation.ai/en/collections/1778760-personalize" target="_blank">Support</Link>
                          <Link className="navbar-item" to="/personalize/pricing/">Pricing</Link>
                        </div>
                      </li>
                      <li>
                        <h3>Blueprint SEO</h3>
                        <div className="submenu">
                          <Link className="navbar-item" to="/blueprint-seo/">Overview</Link>
                        </div>
                      </li>
                      <li>
                        <h3>Company</h3>
                        <div className="submenu">
                          <Link className="navbar-item" to="/about-us">About Us</Link>
                          <Link className="navbar-item" to="/blog">Blog</Link>
                        </div>
                      </li>
                    </ul>
                  </section>
                </div>
              </div>
            </div>
            <div className="column is-3">
              <div className="columns footer__bottom">
                <div className="column footer__bottom--start">
                  <div className="logo">
                    <Link to="/" className="navbar-item">
                      <img
                        src={logo}
                        alt="Human Marketing"
                        style={{ width: "110px" }}
                      />
                    </Link>
                  </div>
                  <SocialIcons/>

                </div>
                <div className="column location has-text-left">
                  <section>
                    <a className="tel has-text-white-ter" href="tel:949-416-2043">949.416.2043</a>
                    <h3 className="has-text-white-ter">AI-powered marketing software <span>built for marketers, by marketers</span></h3>
                    <address><p>9551 Irvine Center Drive, <span>Irvine, CA 92618</span></p></address>
                  </section>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  )
}

export default Footer
