import React from 'react'
import { Link } from 'gatsby'
import personalize from '../img/logo-personalize-teal.png'

const PersonalizeFooterCta = () => {

    return (    
        <div className="columns">
            <div className="column is-offset-1 is-5">
                <div className="logo-personalize">
                <Link className="navbar-item" to="/personalize/">
                    <img
                    src={personalize}
                    alt="Personalize"
                    style={{ width: "153px" }}
                    />
                </Link>
                </div>
                <p>Start sending perfectly-timed marketing messages that your customers actually care about.</p>
            </div>
            <div className="column is-offset-1">
                <div className="demo-btn">
                    <a href="https://inbound.human.marketing/meetings/joel100/personalize-demo" rel="noopener noreferrer" >Schedule A Demo</a>
                </div>
            </div>
        </div>
    )
}

export default PersonalizeFooterCta