import React, { useState } from "react";
import { Link } from "gatsby";
import logo from "../img/logo-human-teal.png";
import usericon from "../img/icons-user-circle.png";
import { useSpring, animated } from "react-spring";
import SocialIcons from '../components/SocialIcons'

/* eslint-disable */

const Navbar = () => {
  const [isActive, setActive] = useState(false);
  const [on, toggle] = useState(false);
  const navAnimation = useSpring({
    opacity: isActive ? `1` : `0`
  });

  return (
    <div style={{ position: 'absolute', zIndex: '400' }}>
    <animated.nav
      className={`navbar main-nav is-fixed-top ${on ? "is-active" : ""}`}
      role="navigation"
      aria-label="main-navigation"
      id="main-nav"
    >
      <div className="container">
        <div className={`navbar-brand ${isActive ? "is-active" : ""}`} >
          {/* Hamburger menu */}
          <div
            className={`navbar-burger burger ${isActive ? "is-active" : ""}`}
            data-target="navMenu"
            onClick={() => setActive(!isActive)}
          >
            <span />
            <span />
            <span />
          </div>
          <Link to="/" className="navbar-item logo" title="Logo">
            <img src={logo} alt="human automation" style={{ width: "100px", maxHeight: "50px" }} />
          </Link>
          <div className="mobile-right">
            <a href="https://personalize.humanautomation.ai/" rel="noopener noreferrer" className="mobile-login"> <img src={usericon} alt="login" style={{ width: "30px", maxHeight: "30px" }} /> </a>
          </div>

        </div>
        <animated.div
          id="navMenu"
          className={`navbar-menu ${isActive ? "is-active" : ""}`}
          style={navAnimation}
        >
          <div className="navbar-start has-text-centered">
            <Link className="navbar-item" to="/personalize">
              <span>Personalize</span>
            </Link>
            <Link className="navbar-item" to="/blueprint-seo">
              <span>Blueprint SEO</span>
            </Link>

            <Link className="navbar-item" to="/about-us">
              About Us
            </Link>
            <a className="navbar-item" href="https://inbound.human.marketing/" rel="noopener noreferrer">
              <span>Blog</span>
            </a>
          </div>
          <div className="navbar-end" >
            <SocialIcons/>
            <div className="mobile-contact">
              <div className="tel-wrapper"><a className="tel" href="tel:949-416-2043">949.416.2043</a></div>
              <p className="mobile-contact-inbound">An Inbound Marketing Agency
              <span>based in Orange County, CA</span></p>

              <address><p>9551 Irvine Center Drive, <span>Irvine, CA 92618</span></p></address>
            </div>
          </div>
        </animated.div>
        <div className="navbar-right">
          <a href="https://personalize.humanautomation.ai/" rel="noopener noreferrer" className="has-text-weight-bold btn nav-cta"> LOG IN </a>
        </div>
      </div>
    </animated.nav>

    </div>
  );
};

export default Navbar;
