import React from 'react'
import Helmet from 'react-helmet'
import Footer from '../components/Footer'
import Navbar from '../components/Navbar'
import './all.sass'
import './scss/_fonts.scss'
import useSiteMetadata from './SiteMetadata'
import '../components/FontAwesome'

const TemplateWrapper = ({ children, software, footerProps }) => {
  const { title, description } = useSiteMetadata()
  return (
    <div>
      <Helmet>
        <html className="has-navbar-fixed-top" lang="en" />
        <title>{title}</title>
        <meta name="description" content={description} />
        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href="/img/h-logo-orange.png"
        />
        <link
          rel="icon"
          type="image/png"
          href="/img/favicon-hm-32x32.png"
          sizes="32x32"
        />
        <link
          rel="icon"
          type="image/png"
          href="/img/favicon-hm-16x16.png"
          sizes="16x16"
        />

        <link
          rel="mask-icon"
          href="/img/safari-pinned-tab.svg"
          color="#ff4400"
        />
        <meta name="theme-color" content="#fff" />

        <meta property="og:type" content="business.business" />
        <meta property="og:title" content={title} />
        <meta property="og:url" content="/" />
        <meta property="og:image" content="/img/logo.svg" />
      </Helmet>

      <Navbar />

      <div style={{position: 'static', zIndex: '200'}}>{children}</div>

      <Footer software={software} {...footerProps} />
    </div>
  )
}

export default TemplateWrapper
