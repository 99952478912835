import React from 'react'
import { Link } from 'gatsby'
import logo from '../img/logo-blueprint-blue-400w.png'

const BlueprintFooterCta = () => {

    return (
        <div className="columns blueprint-footer">
            <div className="column is-offset-1 is-5">
                <div className="blueprint-footer-text">
                    <div className="logo-blueprint">
                    <Link to="/blueprint-seo/">
                        <img src={logo}
                        alt="Blueprint SEO"
                        style={{ maxWidth: "150px", maxHeight: "none" }}
                        />
                    </Link>
                    </div>
                    <h4>Access Blueprint SEO for free</h4>
                    <p> Plan, execute and measure your topic strategy with Blueprint SEO. Try it for free for a limited time. </p>
                </div>
            </div>
            <div className="column is-offset-1">
                <div className="demo-btn">
                    <a href="https://blueprintseo.humanautomation.ai/signup" rel="noopener noreferrer" >Try For Free</a>
                </div>
            </div>
        </div>
    );
};

export default BlueprintFooterCta
