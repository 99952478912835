import { library } from '@fortawesome/fontawesome-svg-core'
import { faAngleRight, faAngleLeft, faQuoteLeft, faCircle, faCheckCircle, faStar } from '@fortawesome/free-solid-svg-icons'
import { fab } from '@fortawesome/free-brands-svg-icons'

library.add(
    fab,
    faStar,
    faCheckCircle,
    faAngleRight,
    faAngleLeft,
    faQuoteLeft,
    faCircle,    
);